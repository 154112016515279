<template>
    <h1>Framtidsvisioner</h1>
    <p>Med nedanstående framtidsvisioner borde man gynna egen, miljövänlig energiproduktion.</p>

    <img src="@/assets/images/ankor.jpg" alt="Ankor" />

    <ul>
        <li>Införa miljöbonus på fastigheter och villor. En lägre fastighetsskatt/avgift kommer att
            sätta fart på solceller och solvärme utbyggnaden.</li>
        <li>Koldioxidersättningen måste värdesättas högre. Det måste kosta att öka mängden
            koldioxid. Elcertifikaten som reglerar koldioxidutsläppen är i dagsläget för låga.</li>
        <li>Politikerna bör införa krav att inga bygglov beviljas utan att någon form av förnybar
            energi är inbyggd i fastigheten. Gäller även större ombyggnader. Samma regler skall
            gälla i hela Sverige.</li>
        <li>Ta bort bygglovavgiften när installation av förnybar energi monteras. Ange minsta
            besparing av koldioxid som måste sparas för reducering av bygglovavgiften.</li>
        <li>Utöka forskning inom högskolor och universitet inom energiområdet, inte bara kärnkraft.
            Varför skall vindkraftverken tillverkas i utlandet? Exportmöjligheter och fler
            arbetstillfällen!</li>
        <li>För att få ner investeringskostnaden bör nya hus förberedas med kanaler i husstommen
            från bottenplatta till taknock eller elcentral. Snabbmontering - låga
            installationskostnader.</li>
        <li>Ersättningen per kWh för solkraft behöver höjas. Investeringen skall följa samma regler
            som industrin i övrigt. Investeringskalkylen skall visa vinst på 10 år.</li>
    </ul>
</template>
