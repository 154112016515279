<template>
    <h1>Bakgrund</h1>

    <p>Göran är en entreprenör som satsar på förnybara energikällor och gjort så under många
        decennier. Det senaste tillskottet med solcellerna under 2010 var ny kunskap. Han
        har ägnat många timmar för att öka kompetensen genom att bland annat besöka
        leverantörer och tillverkare.</p>

    <img src="@/assets/images/goran.jpg" alt="Göran" />

    <p>Det unika är att Göran byggt in många energislag i samma fastighet och att dessa
        samverkar till en bra ekonomi för familjen. Redan 1984 började han ta det första
        steget med att lägga ut vanliga värmeelement på berget bakom huset. Det var första
        generationens solfångare. Elementen blev så varma att man inte kunde hålla dem med
        handen utan att bränna sig. Detta gav mersmak och på den vägen är det. Innovationer
        och tester har avlöst varandra. Nu är tredje generationens solfångare installerad
        2008 - med bättre prestanda.</p>

    <p>En målsättning har varit att satsa på svensktillverkade produkter. Detta ger
        sysselsättning och produktutveckling i Sverige. Produkterna är något dyrare men det
        tjänar samhället på. För att få ett hållbart energisamhälle krävs att alla måste
        medverka.</p>
</template>
