<template>
    <h1>Göran fick stadens miljöpris</h1>

    <p>Mölndals miljöpris är ett hederspris som årligen delas ut som en uppmuntran till en
        pristagare som aktivt verkat för att förbättra miljön i Mölndal. Insatserna kan handla om
        idéer, system, produkter eller förtjänstfullt arbete i största allmänhet.</p>

    <img src="@/assets/images/miljopris.jpg" alt="Miljöpris" />

    <p>Utmärkelsen ges till en person, ett företag eller en organisation som har uträttat något
        positivt för miljön och som förtjänar att uppmärksammas för detta.</p>

    <p>I prismotiveringen står det bland annat "Gör sitt bästa för att förmedla, påverka och sprida
        kunskap om solcellsanläggningar och den positiva verkan som tekniken gör för miljön och
        kommande generationer."</p>

    <p>Prisutdelningen ägde rum onsdagen den 23 maj 2012 vid en ceremoni på Gunnebo Slott i samband
        med en lunch.</p>

    <p>Sedan Göran gick i pension har han tagit emot många studiebesök i villan i Helenevik och
        visat och berättat om solcellsanläggningen och andra investeringar för förnybar energi som
        han gjort.</p>

    <p>Källa: Mölndals-Posten 23/5 2012</p>
</template>
