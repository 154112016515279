<template>
  <h1>Sol- och vindkraft i Mölndal</h1>

  <p>Göran Edvardsson är miljöintresserad och har satsat på förnybara energikällor. Han har haft
    solceller på taket sedan 2010, men solvatten skaffade han redan 1984. När Göran gick i pension
    från ingenjörsjobbet kunde han lägga större kraft på att utveckla närmast en park av förnybara
    energiformer.</p>

  <img src="@/assets/images/huset.jpg" alt="Huset" />

  <p>Byggnationen färdigställdes under 2010 då solcellspaneler placerades på hustaket. Under 2015
    kompletterades anläggningen ytterligare för att utöka produktionen. På berget bakom huset är en
    vindsnurra placerad.</p>

  <p>Görans kompetens har vuxit och många har varit intresserade av projektet bland annat från
    kommunen, bostadsföreningar och privatpersoner. Han mottog Mölndals miljöpris vid en cermoni på
    Gunnebo Slott sommaren 2012.</p>

  <p>Sammanställning av produktionssiffrorna för solcellspanelerna från 2013.</p>

  <table class="table">
    <thead>
      <tr>
        <th>År</th>
        <th>Effekt </th>
        <th>Besparing CO2</th>
        <th>Soltimmar</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>2022</td>
        <td>7636 kWh</td>
        <td>4963 kg</td>
        <td>1981</td>
      </tr>
      <tr>
        <td>2021</td>
        <td>5850 kWh</td>
        <td>3610 kg</td>
        <td>1752</td>
      </tr>
      <tr>
        <td>2020</td>
        <td>9322 kWh</td>
        <td>6525 kg</td>
        <td>1916</td>
      </tr>
      <tr>
        <td>2019</td>
        <td>8881 kWh</td>
        <td>6217 kg</td>
        <td>1751</td>
      </tr>
      <tr>
        <td>2018</td>
        <td>10359 kWh</td>
        <td>7251 kg</td>
        <td>1951</td>
      </tr>
      <tr>
        <td>2017</td>
        <td>9515 kWh</td>
        <td>6661 kg</td>
        <td>1618</td>
      </tr>
      <tr>
        <td>2016</td>
        <td>9808 kWh</td>
        <td>6866 kg</td>
        <td>1692</td>
      </tr>
      <tr>
        <td>2015</td>
        <td>9969 kWh</td>
        <td>6978 kg</td>
        <td>1750</td>
      </tr>
      <tr>
        <td>2014</td>
        <td>9003 kWh</td>
        <td>6302 kg</td>
        <td>1835</td>
      </tr>
      <tr>
        <td>2013</td>
        <td>8466 kWh</td>
        <td>5926 kg</td>
        <td>1931</td>
      </tr>
    </tbody>
  </table>

  <p>Källa: Energimyndigheten och SMHI</p>
</template>
