<template>
    <h1>Energislag</h1>

    <p>Vi har samlat de olika energislagen på denna sidan. Användning sker vid olika årstider eller
        i kombination.</p>

    <ul>
        <li><a href="#solkraft">Solkraft med solceller</a></li>
        <li><a href="#vindkraft">Vindkraft</a></li>
        <li><a href="#solvarme">Solvärme med solfångare</a></li>
        <li><a href="#biobransle">Biobränsle</a></li>
        <li><a href="#varmepump">Värmepump</a></li>
    </ul>

    <h2 id="solkraft">Solkraft med solceller</h2>

    <p>Fördelen med solkraft är att den är ren, lättinstallerad och man sparar koldioxid.
        Växthusgaserna måste minskas på sikt och alla måste hjälpa till. Alla kan göra något -
        solkraft är en del i den processen. </p>

    <img src="@/assets/images/solceller.jpg" alt="Solceller" />

    <p>För varje kW som matas in på elnätet eller som du själv förbrukar i fastigheten sparar du
        ca 0,7 kg koldioxid - det blir mycket per år. Från juni 2010 till februari har ca 17 ton
        koldioxid sparats vid en solkraftsproduktion på 11900 KWh. Från juni 2010 till september
        2020 har ca 66 ton koldioxid sparats vid en solkraftsproduktion på 94400 KWh.</p>

    <img src="@/assets/images/inverter.jpg" alt="Inverter" />
    <p>Solcellspanelerna kan monteras på tak, väggar eller integreras på ett separat stativ med
        möjlighet till solföljare som aktivt följer solen. Detta för att ge maximal solinstrålning
        på solpanelerna, vilket höjer verkningsgraden.</p>

    <p>Total solcellsyta är 84 kvm som är placerat på hela hustaket. Produktionen uppskattades
        2010 till 9000-10000 kWh/år. Utökningen av anläggningen under 2015 på 13.6 kvm med 8
        solpaneler producerar ytterligare 2.08 kW. Solcellerna är av typen monokristallina inkl 3
        st dioder till varje solcellspanel med en prestanda på 180-260 W. Växelriktaren är en
        StecaGrind 2000+ Master och Slavar. Strömmatning till elnätet är fördelade på tre faser.
        Den kalkylerade livslängden är mer än 25 år.</p>

    <h2 id="vindkraft">Vindkraft</h2>

    <p>Vindturbinen på berget bakom fastigheten har en kapacitet på ca 500 kW vid en vindhastighet
        på 14 m/s. Stolpens höjd är 12 meter medräknat vindturbinen och rotordiametern som är 1.1
        meter.</p>
    <img src="@/assets/images/vindturbin.jpg" alt="Vindturbin" />
    <p>Vindkraftverket sköter sig självt, är tyst och ser ut som ett smycke i trädgården. Inget
        bygglov erfordras om längden på vindkraftturbinens stolpe ramlar på den egna tomten. Det är
        få rörliga delar och ingen växellåda. Service och underhåll behöver först utföras efter 10
        år.</p>

    <p>En ökad prestanda erhålles om valfri placering kan väljas inom tomtgränsen. Placering av
        vindturbinen regleras av plan och bygglagen.</p>

    <h2 id="solvarme">Solvärme med solfångare</h2>

    <p>Solfångarytan är 11 kvm som ger mellan 5000-6000 kWh per år. Allt beroende på antalet
        soltimmar. Under 2010 var solfångaren ansluten till en 2700 liter ackumulatortank. Vattnet
        från ackumulatortanken används till förvärmning av varmvatten och till värmesystemet.</p>

    <img src="@/assets/images/solfangare.jpg" alt="Solfångare" />

    <p>Huset har golvvärme så en lägre vattentemperatur för uppvärmning kan användas. En klar fördel
        när ingående vattentemperatur är lägre vår och höst. Ingående vattentemperatur från
        solfångarna kan uppgå till 91 grader, trots att placeringen av solfångarna inte är placerade
        optimalt. En miljövinst är att man inte behöver använda andra energisystem på minst 4-5
        månader.</p>

    <p>För att inte få in legionellabakterien i varmvattensystemet är det installerat en 30 liters
        elektrisk varmvattenberedare i serie efter ackumulatortanken som ökar temperaturen till 60
        grader. Detta för att säkerställa varmvattnet i fastigheten. Om större varmvattenbehov
        behövs installeras en större varmvattenberedare på 60-90 liter eller en plattvärmeväxlare.
    </p>

    <h2 id="biobransle">Biobränsle</h2>

    <p>Bränslepellets tillverkas av restprodukter från sågverk i närområdet. Pelletsen som vi
        använder innehåller bara komprimerat trä, helt utan tillsatser.</p>
    <img src="@/assets/images/pelletsbrannare.jpg" alt="Pelletsbrännare" />

    <p>Den typ av pelletsbrännare som fastigheten valt har en inställningsbar effekt mellan 12,5-30
        kW. Systemet består av pelletsbrännare, styrenhet och pelletsförråd. Pelletsbrännaren kan
        användas separat eller tillsammans med solfångare när inte solfångarna kan generera
        tillräckligt med värme. Pellets är koldioxidneutralt vilket innebär att skogen tar upp den
        koldioxid som förbränningen genererar.</p>

    <p>Att värma upp sin fastighet med pellets behöver inte vara besvärligt. Med ett bra lättarbetat
        pelletsförråd blir det enklare. Förrådet kan vara inbyggt i fastigheten eller hjulförsett.
        Förrådet har sluttande sidor för bästa fall ner till skruven. Ett större inbyggt
        pelletsförråd minskar antalet påfyllningar.</p>

    <h2 id="varmepump">Värmepump</h2>

    <p>Värmepumpar finns i ett stort antal varianter. Förutsättningarna styrs av familjens behov och
        den kapitalinvestering som erfordras. En kombination av olika energiformer kan vara lämpligt
        t ex luft/vatten värmepump och solfångare.</p>

    <p>Huset har en luft/vatten värmepump installerad där värmeeffekten är 6 kW.</p>
</template>
