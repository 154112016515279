import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HemVy from '../views/HemVy.vue';
import BakgrundVy from '../views/BakgrundVy.vue';
import EnergislagVy from '../views/EnergislagVy.vue';
import MiljoprisVy from '../views/MiljoprisVy.vue';
import FramtidsvisionVy from '../views/FramtidsvisionVy.vue';
import ArtiklarVy from '../views/ArtiklarVy.vue';

import '../assets/main.css';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Hem',
    component: HemVy,
  },
  {
    path: '/bakgrund',
    name: 'Bakgrund',
    component: BakgrundVy,
  },
  {
    path: '/energislag',
    name: 'energislag',
    component: EnergislagVy,
  },
  {
    path: '/miljopris',
    name: 'miljopris',
    component: MiljoprisVy,
  },
  {
    path: '/framtidsvision',
    name: 'framtidsvision',
    component: FramtidsvisionVy,
  },
  {
    path: '/artiklar',
    name: 'artiklar',
    component: ArtiklarVy,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
