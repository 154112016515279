<template>
    <h1>Artiklar</h1>
    <p>Intresset för förnyelsebara energislag har varit stort. På denna sida finner
        du några artiklar som publiserats.</p>
    <img src="@/assets/images/tidningar.jpg" alt="Tidningar" />
    <p></p>
    <table>
        <tr>
            <td><a href="/artiklar/villafritid-2021-nr2-solbroderna-har-gott-om-kraft.pdf"
                target="_blank">Solbröderna har gott om kraft</a></td>
            <td>Villa & Fritid 2021 nr 2</td>
        </tr>
        <tr>
            <td><a href="/artiklar/energimiljo-20120618-solbroderna-ar-fulla-av-energi.pdf"
                target="_blank">Solbröderna är fulla av energi</a></td>
            <td>Energi & Miljö nr 6/7 2012</td>
        </tr>
        <tr>
            <td><a href="/artiklar/molndalsposten-20120530-prisas-for-lysande-engagemang.pdf"
                target="_blank">Prisas för lysande engagemang</a></td>
            <td>Mölndals-Posten 30/5 2012</td>
        </tr>
        <tr>
            <td><a href="/artiklar/molndalsposten-20120523-miljopris-till-solcellsbroder.pdf"
                target="_blank">Miljöpris till solcellsbröder</a></td>
            <td>Mölndals-Posten 23/5 2012</td>
        </tr>
        <tr>
            <td><a href="/artiklar/gtbostad-20110927-soltvillingarna.pdf"
                target="_blank">Soltvillingarna</a></td>
            <td>GT Bostad 27/9 2011</td>
        </tr>
        <tr>
            <td><a href="/artiklar/villafritid-2011-nr6-energibroderna-blickar-framat.pdf"
                target="_blank">Energibröderna blickar framat</a></td>
            <td>Villa & Fritid 2011 nr 6</td>
        </tr>
        <tr>
            <td><a href="/artiklar/gp-20100918-tvillingar-som-gar-mot-strommen.pdf"
                target="_blank">Tvillingar som går mot strömmen</a></td>
            <td>Göteborgs-Posten 18/9 2010</td>
        </tr>
    </table>
</template>
